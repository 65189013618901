import { Div, Li } from '@playbooks/interface/html';
import { SectionSubtitle } from '@playbooks/interface/sections';

export const BasicItem = ({ keyName, loading, children, tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-between' space='space-x-8' spacing='py-4' {...tailwind?.li}>
			<SectionSubtitle fontSize='text-sm'>{keyName}</SectionSubtitle>
			<Div display='flex-end' overflow='overflow-hidden' space='space-x-4' flex='grow' {...tailwind?.value}>
				{children}
			</Div>
		</Li>
	);
};
