import { Fragment } from 'react';

import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';
import { Item } from 'molecules/items';
import { listBuilder } from 'utils';

const ItemSkeleton = ({ count, tailwind }) => (
	<Fragment>
		{listBuilder(count).map((v, i) => (
			<Item
				type='text'
				key={i}
				keyName={<SkeletonWrapper width='20%' />}
				value={<SkeletonWrapper width='20%' />}
				width='w-full'
			/>
		))}
	</Fragment>
);

export { ItemSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
