import { useMemo } from 'react';

import { DropHeader } from '@playbooks/interface/drops';
import { Small } from '@playbooks/interface/fonts';
import { Div } from '@playbooks/interface/html';
import { TextLink } from '@playbooks/interface/links';
import { Progress, ProgressBar } from '@playbooks/interface/progress-bars';
import { formatDate } from 'utils';

const UsageProgressBar = ({ subscription }) => {
	// Computed
	const computedValue = useMemo(() => {
		const total = subscription.usage?.totalCredits || 100;
		const used = subscription.usage?.usedCredits || 0;
		return (used / total) * 100;
	}, [JSON.stringify(subscription)]);

	if (subscription.isPaid) return null;
	return (
		<DropHeader spacing='p-4'>
			<Div display='flex-between' space='space-x-2' spacing='pb-1'>
				<Small>Monthly usage</Small>
				<Small>
					{subscription.usage?.usedCredits} / {subscription.usage?.totalCredits}
				</Small>
			</Div>
			<ProgressBar>
				<Progress value={computedValue} />
			</ProgressBar>
			<Small fontSize='text-xs' spacing='pt-1'>
				Your credits will renew {formatDate(subscription.usage?.endDate, 'MMM Do, YYYY')}.
				<TextLink
					size=''
					nextIcon='chevron-right'
					color='blue-500 dark:cyan-500'
					hover='h:underline'
					fontSize='text-inherit'
					space='space-x-1'
					href='/pricing'
					className='ml-2'>
					Upgrade Now
				</TextLink>
			</Small>
		</DropHeader>
	);
};
export { UsageProgressBar };
