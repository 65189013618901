import { useState } from 'react';

import { Drop, DropItem, DropLink, DropList, DropMenu, DropToggle } from '@playbooks/interface/drops';
import { FadIcon } from '@playbooks/interface/icons';

const GuestDrop = ({ variant, rootLink, onNext }) => {
	const [open, setOpen] = useState(false);
	const [ref, setRef] = useState(null);

	// Methods
	const onToggle = () => setOpen(!open);
	const onClose = () => setOpen(false);

	// Render
	return (
		<Drop ref={setRef} open={open} onClose={onClose}>
			<DropToggle variant={variant} size='xs' nextIcon='' active={open} onClick={onToggle}>
				<FadIcon icon='circle-user' fontSize='text-2xl' />
			</DropToggle>

			<DropMenu ref={ref} open={open} spacing='right-0 mt-4' width='w-96'>
				<DropList>
					<DropItem>
						<DropLink alt='login' href='/login'>
							Login
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink alt='register' href='/register'>
							Register
						</DropLink>
					</DropItem>

					<DropItem border='border-b' spacing='px-2' />

					<DropItem>
						<DropLink alt='about' href='/about'>
							About
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink href={process.env.NEXT_PUBLIC_DOCS_DOMAIN}>Docs</DropLink>
					</DropItem>

					<DropItem>
						<DropLink alt='pricing' href='/pricing'>
							Pricing
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink alt='blog' href='/blog'>
							Blog
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink alt='support' href='/support'>
							Support
						</DropLink>
					</DropItem>

					<DropItem border='border-b' spacing='px-2' />

					<DropItem>
						<DropLink alt='terms of service' href='/terms'>
							Terms
						</DropLink>
					</DropItem>
					<DropItem>
						<DropLink alt='privacy policy' href='/privacy'>
							Privacy
						</DropLink>
					</DropItem>
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { GuestDrop };
