import { Div, Span } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const ContentSkeleton = ({ count, tailwind }) => (
	<Div border='border-b' display='flex-between' space='space-x-4' spacing='p-4' width='w-full'>
		<Div display='flex-start' space='space-x-4' width='w-full'>
			<Span flex='shrink-0' width='w-10'>
				<SkeletonWrapper />
			</Span>
			<Span width='w-full'>
				<SkeletonWrapper />
			</Span>
		</Div>
	</Div>
);

export { ContentSkeleton };

// Docs:
// https://github.com/dvtng/react-loading-s#readme
