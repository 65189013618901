import { Badge } from '@playbooks/interface/badges';
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardIcon,
	CardImg,
	CardPretitle,
	CardSubtitle,
	CardText,
	CardTitle,
} from '@playbooks/interface/cards';
import { Div, Span } from '@playbooks/interface/html';
import { LinkWrapper } from '@playbooks/interface/links';
import { Skeleton } from 'molecules/skeletons';

const GridCard = ({
	icon,
	header = null,
	badges,
	status,
	photo,
	pretitle,
	title,
	subtitle,
	text,
	href,
	meta,
	loading,
	tailwind,
	children,
}) => {
	// Render
	if (loading) return <Skeleton type='grid' tailwind={tailwind} />;
	return (
		<Card
			// border=''
			display='flex-column'
			overflow='overflow-hidden'
			height='h-full'
			hover='h:border-opacity-100 dark:h:border-opacity-100'
			{...tailwind?.card}>
			<Div display='flex-end' position='absolute' inset='top-4 right-4' space='space-x-2' zIndex='z-10'>
				{badges}
				{status && <Badge type={status}>{status}</Badge>}
			</Div>
			<LinkWrapper href={href}>
				{header ? (
					header
				) : (
					<CardHeader
						aspect='aspect-[16/9]'
						borderRadius=''
						display='flex-middle'
						overflow='overflow-hidden'
						position='relative'
						width='w-full'
						{...tailwind?.header}>
						{photo ? (
							<CardImg
								src={photo}
								alt='card thumbnail'
								position='absolute'
								scale='h:scale-[105%]'
								transition='transition-all'
								ease='ease'
								{...tailwind?.photo}
							/>
						) : (
							<CardIcon icon={icon} {...icon} {...tailwind?.icon} />
						)}
					</CardHeader>
				)}
			</LinkWrapper>
			<CardBody flex='grow' space='space-y-2' {...tailwind?.body}>
				{pretitle && <CardPretitle fontSize='text-sm'>{pretitle}</CardPretitle>}
				{title && (
					<CardTitle {...tailwind?.title}>
						<LinkWrapper href={href} textTransform='h:underline'>
							{title}
						</LinkWrapper>
					</CardTitle>
				)}
				{subtitle && <CardSubtitle className='line-clamp-2'>{subtitle}</CardSubtitle>}
				{text && <CardText {...tailwind?.text}>{text}</CardText>}
				{meta && <Span className='line-clamp-1'>{meta}</Span>}
			</CardBody>
			{children && <CardFooter {...tailwind?.footer}>{children}</CardFooter>}
		</Card>
	);
};

export { GridCard };
