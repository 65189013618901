import { Badge } from '@playbooks/interface/badges';
import { BtnWrapper } from '@playbooks/interface/buttons';
import {
	Card,
	CardActions,
	CardBody,
	CardHeader,
	CardIcon,
	CardImg,
	CardPretitle,
	CardSubtitle,
	CardText,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { LinkWrapper } from '@playbooks/interface/links';
import { Skeleton } from 'molecules/skeletons';

export const ActionCard = ({
	icon,
	header = null,
	photo,
	badges,
	status,
	pretitle,
	title,
	subtitle,
	text,
	meta,
	loading,
	tailwind,
	children,
}) => {
	// Render
	if (loading) return <Skeleton type='action' tailwind={tailwind} />;
	return (
		<Card
			border='border-b'
			borderRadius=''
			display='flex-between'
			hover='group-hover:border-opacity-100 group-hover:dark:border-opacity-100'
			space='space-x-8'
			spacing='py-4'
			{...tailwind?.card}>
			<Span display='flex-start' space='space-x-4' width='w-full'>
				{header ? (
					header
				) : (
					<CardHeader
						aspect='aspect-[1/1]'
						borderRadius='rounded-md'
						display='flex-middle'
						overflow='overflow-hidden'
						spacing=''
						width='w-24'
						{...tailwind?.header}>
						{photo ? (
							<CardImg src={photo} alt={title + ' photo'} width='w-full' />
						) : (
							<CardIcon icon={icon} {...icon} {...tailwind?.icon} />
						)}
					</CardHeader>
				)}
				<CardBody display='flex-column' spacing='' width='w-full' {...tailwind?.body}>
					{pretitle && <CardPretitle>{pretitle}</CardPretitle>}
					<Span display='flex-start' space='space-x-4'>
						<CardTitle>{title}</CardTitle>
						<Span display='flex-start' space='space-x-2'>
							{badges}
							{status && (
								<Badge size='xs' type={status}>
									{status}
								</Badge>
							)}
						</Span>
					</Span>
					{subtitle && <CardSubtitle className='line-clamp-2'>{subtitle}</CardSubtitle>}
					{text && <CardText>{text}</CardText>}
					{meta}
				</CardBody>
			</Span>
			<CardActions display='flex-end' flex='shrink-0' {...tailwind?.actions}>
				{children}
			</CardActions>
		</Card>
	);
};

export const ActionBtnCard = props => (
	<BtnWrapper onClick={props.onClick} cursor='cursor-pointer' group='group' width='w-full'>
		<ActionCard {...props}>
			<FarIcon icon='chevron-right' />
		</ActionCard>
	</BtnWrapper>
);

export const ActionLinkCard = props => (
	<LinkWrapper href={props.href} cursor='cursor-pointer' group='group' width='w-full'>
		<ActionCard {...props}>
			<FarIcon icon='chevron-right' />
		</ActionCard>
	</LinkWrapper>
);
