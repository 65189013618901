import { useRouter } from 'next/router';

import { Avatar, AvatarBody, AvatarImg, AvatarText } from '@playbooks/interface/avatars';
import { Icon } from '@playbooks/interface/icons';
import { LinkWrapper } from '@playbooks/interface/links';
import { truncate } from 'utils';

export const TableAvatar = ({ icon = 'database', thumbnail, title, length = 30, href, tailwind }) => {
	const router = useRouter();
	const params = new URLSearchParams({ archived: router.query.archived?.toString() });
	const url = router.query.archived ? href + '?' + params.toString() : href;

	// Render
	return (
		<LinkWrapper href={url} alt={title} group='group' flex='shrink-0'>
			<Avatar display='flex-start' flex='shrink-0'>
				{thumbnail ? (
					<AvatarImg size='xs' src={thumbnail} alt={`${title} photo`} />
				) : (
					<Icon type='far' icon={icon} {...tailwind?.icon} />
				)}
				<AvatarBody hover='group-hover:underline' flex='shrink-0'>
					<AvatarText>{truncate(title, length)}</AvatarText>
				</AvatarBody>
			</Avatar>
		</LinkWrapper>
	);
};
